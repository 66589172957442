import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, withModifiers as _withModifiers, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, withCtx as _withCtx, createBlock as _createBlock, normalizeClass as _normalizeClass, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "timi-entry-top-actionbar" }
const _hoisted_2 = { class: "actionbar-content" }
const _hoisted_3 = { class: "timi-standard-header" }
const _hoisted_4 = { class: "timi-timeentryform-container" }
const _hoisted_5 = {
  key: 0,
  class: "timi-timeentryform-content"
}
const _hoisted_6 = { class: "form-layout" }
const _hoisted_7 = { class: "timi-form-item" }
const _hoisted_8 = { class: "timi-form-item" }
const _hoisted_9 = { class: "timi-form-item" }
const _hoisted_10 = { class: "form-footer" }
const _hoisted_11 = { class: "listitem__content" }
const _hoisted_12 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_IconButton = _resolveComponent("IconButton")!
  const _component_UiTimeInput = _resolveComponent("UiTimeInput")!
  const _component_SelectSingleSearch = _resolveComponent("SelectSingleSearch")!
  const _component_Button = _resolveComponent("Button")!
  const _component_ListItem = _resolveComponent("ListItem")!
  const _component_List = _resolveComponent("List")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createVNode(_component_IconButton, {
          class: "btn-cancel",
          icon: _ctx.store.getters.svgIcons.close,
          label: _ctx.$t('button.cancel'),
          dense: true,
          onOnClick: _ctx.handleCancel
        }, null, 8, ["icon", "label", "onOnClick"])
      ])
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("h1", null, _toDisplayString(_ctx.$t('route.editTimeentry')), 1)
    ]),
    _createElementVNode("div", _hoisted_4, [
      ((_ctx.works?.items && _ctx.projects))
        ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
            _createElementVNode("div", _hoisted_6, [
              _createElementVNode("form", {
                onSubmit: _cache[1] || (_cache[1] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.handleSubmitPrevent && _ctx.handleSubmitPrevent(...args)), ["prevent"]))
              }, [
                _createElementVNode("div", _hoisted_7, [
                  _createElementVNode("label", null, _toDisplayString(_ctx.$t('label.time')), 1),
                  _createVNode(_component_UiTimeInput, {
                    value: _ctx.form.time,
                    onOnChange: _cache[0] || (_cache[0] = (v) => _ctx.handleTimeChanged('time', v))
                  }, null, 8, ["value"])
                ]),
                _createElementVNode("div", _hoisted_8, [
                  _createVNode(_component_SelectSingleSearch, {
                    placeholder: _ctx.$t('placeholder.selectWork'),
                    item: { label: _ctx.$t('label.work'), name: 'editTimeEntrySelectWork' },
                    options: _ctx.works?.items,
                    labelProperty: "title",
                    value: { value: _ctx.form.workId, text: _ctx.selectedWork?.title },
                    allowCreateNew: false,
                    emptyStateText: _ctx.$t('emptyState.works'),
                    onOnChange: _ctx.handleWorkChange
                  }, null, 8, ["placeholder", "item", "options", "value", "emptyStateText", "onOnChange"])
                ]),
                _createElementVNode("div", _hoisted_9, [
                  _createVNode(_component_SelectSingleSearch, {
                    placeholder: _ctx.$t('placeholder.selectProject'),
                    item: { label: _ctx.$t('label.project'), name: 'editTimeEntrySelectProject' },
                    options: _ctx.projects,
                    labelProperty: "title",
                    value: { value: _ctx.form.projectId, text: _ctx.selectedProject?.title },
                    allowCreateNew: false,
                    emptyStateText: _ctx.$t('emptyState.projects'),
                    onOnChange: _ctx.handleProjectChange
                  }, null, 8, ["placeholder", "item", "options", "value", "emptyStateText", "onOnChange"])
                ]),
                _createElementVNode("div", _hoisted_10, [
                  _createVNode(_component_Button, {
                    text: _ctx.$t('button.save'),
                    primary: true,
                    raised: true,
                    onOnClick: _ctx.handleSave
                  }, null, 8, ["text", "onOnClick"])
                ])
              ], 32)
            ]),
            false
              ? (_openBlock(), _createElementBlock("div", {
                  key: 0,
                  class: _normalizeClass(["ww-tabs-tab", {'active': _ctx.activeTab == 0}])
                }, [
                  _createElementVNode("h3", null, _toDisplayString(_ctx.$t('project.selectYourProject')), 1),
                  _createVNode(_component_List, {
                    isEmpty: _ctx.filteredProjectList.length>0 ? false : true,
                    emptyStateText: _ctx.$t('emptyState.projects'),
                    raised: true
                  }, {
                    default: _withCtx(() => [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.filteredProjectList, (project) => {
                        return (_openBlock(), _createBlock(_component_ListItem, {
                          key: project.uid,
                          onClick: ($event: any) => (_ctx.handleSelectProject(project)),
                          interactive: true
                        }, {
                          default: _withCtx(() => [
                            _createElementVNode("div", _hoisted_11, [
                              _createElementVNode("h3", null, _toDisplayString(project.title), 1),
                              _createElementVNode("p", null, _toDisplayString(project?.customer?.custName), 1)
                            ])
                          ]),
                          _: 2
                        }, 1032, ["onClick"]))
                      }), 128))
                    ]),
                    _: 1
                  }, 8, ["isEmpty", "emptyStateText"])
                ], 2))
              : _createCommentVNode("", true),
            false
              ? (_openBlock(), _createElementBlock("div", {
                  key: 1,
                  class: _normalizeClass(["ww-tabs-tab", {'active': _ctx.activeTab == 1}])
                }, [
                  _createElementVNode("h3", null, _toDisplayString(_ctx.$t('work.selectYourWork')), 1),
                  _createVNode(_component_List, {
                    isEmpty: _ctx.filteredWorkList.length>0 ? false : true,
                    emptyStateText: _ctx.$t('emptyState.works'),
                    class: "timeentry-select-work"
                  }, {
                    default: _withCtx(() => [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.filteredWorkList, (work) => {
                        return (_openBlock(), _createBlock(_component_ListItem, {
                          key: work.uid,
                          onClick: ($event: any) => (_ctx.handleSelectWork(work)),
                          interactive: true
                        }, {
                          default: _withCtx(() => [
                            _createElementVNode("div", null, [
                              _createElementVNode("p", null, _toDisplayString(work.title), 1)
                            ])
                          ]),
                          _: 2
                        }, 1032, ["onClick"]))
                      }), 128))
                    ]),
                    _: 1
                  }, 8, ["isEmpty", "emptyStateText"])
                ], 2))
              : _createCommentVNode("", true)
          ]))
        : (_openBlock(), _createElementBlock("div", {
            key: 1,
            class: "svgicon svgicon--loader",
            innerHTML: _ctx.store.getters.svgIcons.loader
          }, null, 8, _hoisted_12))
    ])
  ]))
}